import type { PropType } from 'vue'
import type { PaginationState } from '@tanstack/vue-table'
import { FormKit } from '@formkit/vue'
import { Icon } from '#components'

export default defineComponent({
  name: 'DataTablePagination',
  props: {
    rowsPerPageOptions: {
      type: Array as PropType<number[]>,
      required: true,
    },
    canPreviousPage: {
      type: Boolean,
      required: true,
    },
    canNextPage: {
      type: Boolean,
      required: true,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<PaginationState>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['previousPage', 'nextPage', 'pageSize'],
  setup(props, { emit }) {
    const rowsPerPageOptions = computed(() => {
      return props.rowsPerPageOptions.map((value) => ({
        label: String(value),
        value,
      }))
    })

    const startIndex = computed(() => {
      return props.pagination.pageIndex * props.pagination.pageSize + 1
    })
    const endIndex = computed(() => {
      return Math.min(
        (props.pagination.pageIndex + 1) * props.pagination.pageSize,
        props.totalRecords,
      )
    })

    const disabled = computed(() => props.loading && !props.totalRecords)

    return () => (
      <div class="flex items-center justify-end gap-4 border-t border-gray-800 px-4 py-2.5 text-sm">
        <div class="flex items-center gap-2">
          <span> rows per page: </span>
          <FormKit
            type="listbox"
            disabled={disabled.value}
            options={rowsPerPageOptions.value}
            format="value"
            model-value={props.pagination.pageSize}
            onUpdate:model-value={(value) => emit('pageSize', value)}
          />
        </div>
        <span class="flex gap-1">
          <span>
            {startIndex.value}-{endIndex.value}
          </span>
          <span class="text-gray-400">of</span>
          <span>{props.totalRecords}</span>
        </span>
        <div class="flex items-center gap-2">
          <button
            disabled={disabled.value || !props.canPreviousPage}
            class={[
              'flex rounded-full text-black disabled:opacity-50 dark:text-white',
              !disabled.value &&
                props.canPreviousPage &&
                'hover:bg-primary cursor-pointer hover:text-black dark:hover:text-black',
            ]}
            onClick={() => emit('previousPage')}
          >
            <Icon
              name="chevron-left"
              filled
              class="flex h-8 w-8 items-center justify-center text-current dark:group-hover:text-black"
            />
          </button>
          <button
            disabled={disabled.value || !props.canNextPage}
            class={[
              'flex rounded-full text-black disabled:opacity-50 dark:text-white',
              !disabled.value &&
                props.canNextPage &&
                'hover:bg-primary cursor-pointer hover:text-black dark:hover:text-black',
            ]}
            onClick={() => emit('nextPage')}
          >
            <Icon
              name="chevron-right"
              filled
              class="flex h-8 w-8 items-center justify-center text-current dark:group-hover:text-black"
            />
          </button>
        </div>
      </div>
    )
  },
})
